<template>
  <a-drawer
    title="选择签章"
    placement="right"
    destroyOnClose
    :visible="visible"
    @close="closeModal"
    width="50%"
  >
    <div style="height: 100%;width:100%;">
      <div
        class="show-info"
        style="text-align:center;"
      >
        <div style="text-align:center;">
          <div style="height:842px;width: 595px;margin:auto;">
            <vueCropper
              ref="cropper"
              :img="option.img"
              :outputSize="option.size"
              :outputType="option.outputType"
              :info="true"
              :full="option.full"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :fixedBox="option.fixedBox"
              :original="option.original"
              :autoCrop="option.autoCrop"
              :autoCropWidth="option.autoCropWidth"
              :autoCropHeight="option.autoCropHeight"
              :centerBox="option.centerBox"
              :high="option.high"
              :infoTrue="option.infoTrue"
              :cropOffsertX="0"
              :cropOffsertY="0"
              @realTime="realTime"
              @imgLoad="imgLoad"
              @cropMoving="cropMoving"
              :enlarge="option.enlarge"
            ></vueCropper>
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 10px 0;"></div>
    <div :style="{ position: 'absolute', left: 0, bottom: 0, width: '100%', borderTop: '1px solid #e9e9e9',
        padding: '10px 16px', background: '#fff', textAlign: 'right' }">
      <a-button
        :style="{marginRight: '8px'}"
        @click="closeModal"
      >取消</a-button>
      <a-button
        @click="save"
        type="primary"
      >确认</a-button>
    </div>
  </a-drawer>
</template>
<script>
import { VueCropper } from 'vue-cropper'

// 将file文件上传转化为base64进行显示
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    /// FileReader类就是专门用来读文件的
    const reader = new FileReader()
    // 开始读文件
    // readAsDataURL: dataurl它的本质就是图片的二进制数据， 进行base64加密后形成的一个字符串，
    reader.readAsDataURL(file)
    // 成功和失败返回对应的信息，reader.result一个base64，可以直接使用
    reader.onload = () => resolve(reader.result)
    // 失败返回失败的信息
    reader.onerror = error => reject(error)
  })
}

export default {
  components: {
    VueCropper
  },
  props: {
    autographType: {
      type: String,
      default: '1'
    },
    selectApi: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      visible: false,
      option: {
        // img: require('../../../assets/images/a4.jpg'),
        img: '',
        size: 1,
        outputType: 'jpg',
        autoCrop: true,
        autoCropWidth: 265,
        autoCropHeight: 100
      },
      record: {}
    }
  },

  mounted () {
  },

  methods: {
    openModal (file, record) {
      this.record = record
      getBase64(file).then(res => {
        this.option.img = res
      }).catch(() => { })
      this.visible = true
    },

    closeModal () {
      this.visible = false
    },

    realTime (data, x, y) {
    },

    imgLoad (data) { },

    cropMoving (data) {
    },

    transformFile (imgBlob) {
      return new window.File([imgBlob], Math.random() + '.png', {
        type: imgBlob.type
      })
    },

    save () {
      // 二进制数据
      this.$refs.cropper.getCropBlob(data => {
        // do something
        console.log(this.transformFile(data))
        const formData = new FormData()
        formData.append('file', this.transformFile(data))
        formData.append('userId', this.record.id)
        formData.append('deptId', this.record.id)
        this.selectApi(formData).then(res => {
          this.closeModal()
          this.$emit('closeModal')
        }).catch(() => { })
      })
      // base64格式数据
      // this.$refs.cropper.getCropData(data => {
      //   console.log(data, 'datadata')
      //   const img = new Image()
      //   img.src = data
      //   const newWin = window.open('', '_blank')
      //   newWin.document.write(img.outerHTML)
      //   newWin.document.title = '图片'
      //   newWin.document.close()
      // })
    }
  }
}
</script>
<style lang="less" scoped>
</style>
